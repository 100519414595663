<template>
	<div class="">
		<div>
			<!-- bannerImg -->
			<el-image style="height: 300px; width: 100%" :src="meeting.spareFieldO"></el-image>

		</div>

		<div style="display: flex; margin-top: 40px;">
			<div style="width: 77%; margin-right: 2%">
				<div style="display: flex; margin-top: 20px">
					<div class="num" style="margin-top: 5px"></div>
					<div style="font-size: 24px; color: #333333">会议详情</div>
				</div>
				<div>
					<div class="meetingDetailsBg1">
						<div style="width: 20%">
							<el-image :src="require('@/assets/images/meetingDetailsBg1.png')"
								style="width: 70%; height: 130px; padding: 30px">
							</el-image>
						</div>
						<div style="width: 60%">
							<p class="one" style="font-size: 22px; color: #1b5faa">
								{{meeting.meetingName}}
							</p>
							<p class="one" style="margin-top: -17px; color: #333333">
								{{meeting.meetingEnName}}
							</p>
							<div style="line-height: 6px; color: #767388; margin-top: 25px">
								<p>会议时间：{{meeting.meetingDate}}</p>
								<p>会议地点：{{meeting.meetingAddr}}</p>
								<p>
									会议官网：<el-link :underline="false" style="color: #1b5faa" :href="meeting.meetingWeb"
										target="_blank">{{meeting.meetingWeb}}</el-link>
								</p>
								<p>主办单位：{{meeting.meetingSponsor}}</p>
							</div>
						</div>
						<div style="width: 20%; text-align: center">
							<!-- <p>距离会议开始还有</p>
							<p style="margin-top: -10px">
								<span style="
								margin-right: 15px;
								color: #d40016;
								font-size: 35px;
								font-weight: 800;
							  ">13</span><span>天</span>
							</p> -->
							<div style="margin-top: 55px">
								<el-button type="danger" @click="meetingWeb()">报名参会</el-button>
							</div>
							<div style="margin-top: 10px">
								<el-button type="primary" @click="meetingWeb()">论文投稿</el-button>
							</div>
						</div>
					</div>
				</div>
				<div style="margin-top: 40px">
					<div class="meetingDetailsBg2">
						<p>
							<i class="el-icon-folder-opened meetingDetailsBg3"
								style="color: #0c8aec"></i><span>会议简介</span>
						</p>
						<p class="meetingDetailsBg4">
							{{meeting.meetingDetails}}
						</p>
					</div>
					<div class="meetingDetailsBg2" style="margin-top: 20px">
						<p>
							<i class="el-icon-collection meetingDetailsBg3" style="color: red"></i><span>会议征文</span>
						</p>
						<p class="meetingDetailsBg4" v-for="(item, itemIndex) in manuscriptsT" :key="itemIndex">
							<span>{{item.fieldI}}</span>
						</p>
						<ul style="font-size: 15px; color: #9c9c9c" v-for="(item, itemIndex) in manuscriptsL"
							:key="itemIndex">
							<li>{{item.fieldA}}</li>
						</ul>
						<!-- <div>
							<el-link style="color: #1b5faa">查看更多征稿主题>></el-link>
						</div> -->
					</div>
					<div class="meetingDetailsBg2" style="margin-top: 20px">
						<p>
							<i class="el-icon-s-custom meetingDetailsBg3" style="color: #1e00ee"></i><span>主讲嘉宾</span>
						</p>
						<div style="display: flex; margin-top: 20px" v-for="(item, itemIndex) in speakers"
							:key="itemIndex">
							<div style="width: 15%">
								<div>
									<el-image :src="item.fieldC" style="width: 110px; height: 147px"></el-image>
								</div>
								<div style="line-height: 10px">
									<p>{{ item.fieldA }}</p>
									<p style="color: #797979">{{ item.fieldB }}</p>
								</div>
							</div>
							<div style="width: 85%">
								<p class="meetingDetailsBg5">
									{{ item.fieldI }}
								</p>
								<p>
									<span>Website:</span>
									<!-- <el-button type="info" size="mini" style="float: right">Brief CV</el-button> -->
								</p>
								<p>
									<el-link :underline="false" style="color: #1b5faa" :href="item.fieldD"
										target="_blank">{{ item.fieldD }}</el-link>
								</p>
							</div>
						</div>
						<!-- <div>
							<el-link style="color: #1b5faa">查看更多主讲嘉宾>></el-link>
						</div> -->
					</div>
					<div class="meetingDetailsBg2" style="margin-top: 20px">
						<p>
							<i class="el-icon-date meetingDetailsBg3" style="color: #dec01a"></i><span>会议日程</span>
						</p>
						<div>
							<el-table :data="schedule" :span-method="objectSpanMethod" border empty-text="暂无数据"
								style="width: 100%; margin-top: 20px" :header-cell-style="headClass">
								<el-table-column prop="fieldA" label="大会日期" width="300">
								</el-table-column>
								<el-table-column prop="fieldB" label="大会时间">
								</el-table-column>
								<el-table-column prop="fieldC" label="大会内容">
								</el-table-column>
							</el-table>
						</div>
					</div>
					<div class="meetingDetailsBg2" style="margin-top: 20px">
						<p>
							<i class="el-icon-discover meetingDetailsBg3" style="color: green"></i><span>会议注册</span>
						</p>
						<div>
							<div class="meetingDetailsBg6">
								<p>注册方式:</p>
								<div style="margin-top: 20px;line-height: 22px;" v-for="(item, itemIndex) in register"
									:key="itemIndex">
									{{item.fieldA}}
								</div>
								<p style="margin-top: 20px;">注册费用：</p>
							</div>
							<div>
								<el-table :data="cost" border style="width: 100%; margin-top: 20px" empty-text="暂无数据"
									:header-cell-style="headClass">
									<el-table-column prop="fieldA" label="参会类型">
									</el-table-column>
									<el-table-column prop="fieldB" label="注册费用 (RMB / USD)">
									</el-table-column>
								</el-table>
							</div>
							<div class="meetingDetailsBg6">
								<p>支付方式：</p>
								<div v-for="(item, itemIndex) in payment" :key="itemIndex">
									<!-- {{item.fieldI}} -->
									<div v-html="item.fieldI" style="line-height: 20px;"></div>
								</div>
							</div>
						</div>
					</div>
					<div class="meetingDetailsBg2" style="margin-top: 20px">
						<p>
							<i class="el-icon-phone-outline meetingDetailsBg3"
								style="color: #14d4d8"></i><span>联系我们</span>
						</p>
						<div class="meetingDetailsBg6" v-for="(item, itemIndex) in contact" :key="itemIndex">
							<!-- {{item.fieldI}} -->
							<div v-html="item.fieldI" style="line-height: 20px;"></div>
						</div>

					</div>
				</div>
			</div>
			<div style="width: 21%">
				<div
					style=" height: 250px; margin-top: 80px;background-image: linear-gradient(#e66465, #bb1706);color: white;padding-left: 20px;padding-top: 1px;">
					<p><span style="font-size: 22px;margin-right: 5px;font-weight: 700;">重要日期</span><span
							style="font-weight: 700;font-style: italic;font-size: 20px;color: #e69b9b;">importantData</span>
					</p>
					<p class="one"><span style="">在线会议日期:</span><span
							style="">{{importantData[0]==null?'暂无':importantData[0].fieldA}}</span></p>
					<p class="one"><span style="">提交截止日期:</span><span
							style="">{{importantData[0]==null?'暂无':importantData[0].fieldB}}</span></p>
					<p class="one"><span style="">录用时间:</span><span
							style="">{{importantData[0]==null?'暂无':importantData[0].fieldC}}</span></p>
					<p class="one"><span style="">注册截止日期:</span><span
							style="">{{importantData[0]==null?'暂无':importantData[0].fieldD}}</span></p>
				</div>
				<!-- <div v-for="(item, itemIndex) in advertisement.splice(0,1)" :key="advertisement">
					<el-image  :src="item.fieldA"
						style="width: 300px; height: 250px; margin-top: 40px">
					</el-image>
				</div> -->
				<div style="margin-top: 20px">
					<el-button type="primary" icon="el-icon-edit-outline" @click="releaseMeeting()" style="
              background-image: linear-gradient(
                90deg,
                #1b5faa 0%,
                #00b4e4 100%
              );
              width: 100%;
            ">发布会议</el-button>
				</div>
				<div style="display: flex; margin-top: 20px">
					<div class="num"></div>
					<div style="font-weight: 700">推荐会议</div>
				</div>
				<div style="margin-top: 20px;height: 250px; border: 1px solid rgb(118, 114, 136);"
					v-for="(item, itemIndex) in advertisement" :key="itemIndex">
					<el-image :src="item.spareFieldO" style="width: 300px; height: 85px"></el-image>
					<div class="one" :title="item.meetingName"
						style="margin-top: 8px;font-size: 16px;padding-right: 10px;padding-left: 10px;">
						<el-link href="javascript:void(0);" @click="details(itemIndex)">{{item.meetingName}}</el-link>
					</div>
					<div class="one" :title="item.meetingEnName"
						style="margin-top: 8px;padding-right: 10px;padding-left: 10px;">
						{{item.meetingEnName}}
					</div>
					<div class="one" :title="item.meetingDate"
						style="margin-top: 8px;padding-right: 10px;padding-left: 10px;">
						{{item.meetingDate}}
					</div>
					<div class="one" :title="item.meetingWeb"
						style="margin-top: 8px;margin-bottom: 8px;padding-right: 10px;padding-left: 10px;">
						<!-- <el-link :href="item.meetingWeb" target="_blank">{{item.meetingWeb}}</el-link> -->
						{{item.meetingAddr}}
					</div>
				</div>
			</div>
		</div>
		<div style="margin-top: 40px">
			<div style="display: flex">
				<div class="num" style="margin-top: 5px"></div>
				<div style="font-size: 24px; color: #333333">精选课程</div>
			</div>
			<div style="margin-top: 40px">
				<div style="margin-top: 40px;display: flex;">
					<div v-for="(item, itemIndex) in curriculumList" :key="itemIndex"
						style="width: 350px; white-space: nowrap;flex-flow: column nowrap;align-items: center;text-align: center;"
						@click="curriculumDetails(itemIndex)">
						<el-image :src="item.photoA" style="width: 300px; height: 250px"></el-image>
					</div>
				</div>
			</div>
		</div>
	</div>
	<el-dialog title="免费发布会议 Conference Reseale" v-model="dialogFormVisible">
		<!-- <slot name="title">
      <el-image
        :src="require('@/assets/images/release.png')"
        style="width: 100%; height: 100px"
      >
      </el-image>
    </slot> -->
		<div class="journalDetails2-1">
			<p style="padding: 10px">
				<span>本平台为学术界相关人员提供免费发布会议相关信息的服务，在这里你可以<span style="color: red">免费</span>
					发布会议，请按照要求填写以下表格信息，信息提交后经平台审核通过后即可在平台发布会议！</span>
			</p>
		</div>
		<div class="journalDetails2-2">
			<el-form :model="releaseForm" :inline="true" :rules="rules" ref="releaseForm">
				<el-form-item label="会议名称" label-width="100px" required style="width: 100%" prop="meetingName">
					<el-input v-model="releaseForm.meetingName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="英文名称" label-width="100px" style="width: 100%" prop="meetingEnName">
					<el-input v-model="releaseForm.meetingEnName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="会议类型" label-width="100px" required prop="meetingType">
					<el-select v-model="releaseForm.meetingType" placeholder="会议类型">
						<el-option label="EI会议" value="EI会议"></el-option>
						<el-option label="SCI会议" value="SCI会议"></el-option>
						<el-option label="CPCI会议" value="CPCI会议"></el-option>
						<el-option label="知名学术会议" value="知名学术会议"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="学科分类" label-width="100px" required prop="subjectType">
					<el-select v-model="releaseForm.subjectType" placeholder="学科分类">
						<el-option label="学科1" value="学科1"></el-option>
						<el-option label="学科2" value="学科2"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="会议地点" label-width="100px" required prop="meetingAddr" style="width: 100%">
					<el-input v-model="releaseForm.meetingAddr" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="会议时间" label-width="100px" required style="width: 100%" prop="meetingDate">
					<el-date-picker v-model="releaseForm.meetingDate" type="daterange" value-format="YYYY-MM-DD"
						unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
						:shortcuts="shortcuts">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="会议官网" label-width="100px" required prop="meetingWeb">
					<el-input v-model="releaseForm.meetingWeb" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="是否检索历史认证" required prop="isCheck">
					<el-select v-model="releaseForm.isCheck" disabled="" placeholder="是否检索历史认证">
						<el-option label="已认证" value="已认证"></el-option>
						<el-option label="未认证" value="未认证"></el-option>
						<el-option label="审核中" value="审核中"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="会议详细介绍" label-width="100px" style="width: 100%" prop="meetingDetails">
					<el-input type="textarea" v-model="releaseForm.meetingDetails"></el-input>
				</el-form-item>
				<el-form-item label="会议主办方" style="width: 100%" label-width="100px" required prop="meetingSponsor">
					<el-input v-model="releaseForm.meetingSponsor" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="会议承办方" style="width: 100%" label-width="100px" prop="meetingUndertaker">
					<el-input v-model="releaseForm.meetingUndertaker" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系人" label-width="100px" required prop="contacts">
					<el-input v-model="releaseForm.contacts" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系电话" label-width="100px" required prop="phone">
					<el-input v-model="releaseForm.phone" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="联系邮箱" label-width="100px" required prop="Email">
					<el-input v-model="releaseForm.Email" autocomplete="off"></el-input>
				</el-form-item>
				<br />
				<el-form-item style="width: 100%">
					<el-upload class="upload-demo" ref="upload" action="http://47.97.203.109:8066/upload/file"
						:on-remove="handleRemove" :on-success="onSuccess" multiple :limit="3" :on-exceed="handleExceed"
						:file-list="fileList">
						<el-button size="medium" type="info">上传附件</el-button>
						<template #tip>
							<div class="el-upload__tip" style="color: red">
								注：文档支持.zip .rar .doc .docx .txt .pdf等格式
							</div>
						</template>
					</el-upload>
				</el-form-item>
				<br />
				<el-form-item style="text-align: center; width: 100%">
					<el-button type="primary" @click="onSubmit('releaseForm')"
						style="background-color: #1b5faa; width: 282px">立即发布</el-button>
				</el-form-item>
			</el-form>
		</div>
	</el-dialog>
</template>
<script>
	import $http from "@/request/http";
	export default {
		data() {
			return {
				meeting: {},
				fileList: [],
				dialogFormVisible: false,
				headClass() {
					return "text-align: center;background:#767288;color:white";
				},
				curriculumList: [],
				bannerImg: '',
				banner: [],
				manuscriptsT: [], //征文text
				manuscriptsL: [], //征文list
				speakers: [], //嘉宾
				schedule: [], //日程
				register: [], //注册方式
				cost: [], //费用
				payment: [], //支付方式
				contact: [], //联系我们
				importantData: [], //重要日期
				advertisement: [], //广告位
				releaseForm: {
					meetingName: "",
					meetingEnName: "",
					meetingType: "",
					subjectType: "",
					meetingAddr: "",
					meetingDate: "",
					meetingWeb: "",
					isCheck: "未认证",
					meetingDetails: "",
					meetingSponsor: "",
					meetingUndertaker: "",
					contacts: "",
					phone: "",
					Email: "",
				},
				rules: {
					meetingName: [{
						required: true,
						trigger: "blur",
						message: "请输入会议名称"
					}, ],
					meetingType: [{
						required: true,
						trigger: "blur",
						message: "请输入会议类型"
					}, ],
					// subjectType: [{
					// 	required: true,
					// 	trigger: "blur",
					// 	message: "请输入学科分类"
					// }, ],

					meetingDate: [{
						required: true,
						trigger: "blur",
						message: "请输会议时间"
					}, ],
					meetingAddr: [{
						required: true,
						trigger: "blur",
						message: "请输入会议地点"
					}, ],
					isCheck: [{
						required: true,
						trigger: "blur",
						message: "请输入是否检索认证"
					}, ],
					meetingWeb: [{
						required: true,
						trigger: "blur",
						message: "请输入会议官网"
					}, ],
					meetingSponsor: [{
						required: true,
						trigger: "blur",
						message: "请输入会议主办方"
					}, ],
					contacts: [{
						required: true,
						trigger: "blur",
						message: "请输入联系人"
					}, ],
					phone: [{
						required: true,
						trigger: "blur",
						message: "请输入联系电话"
					}],
					Email: [{
						required: true,
						trigger: "blur",
						message: "请输入联系邮箱"
					}],
				},
			};
		},

		methods: {
			details(index) {
				this.meeting = this.advertisement[index]
				this.queryMeetingOtherData()
			},
			handleExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
					 files.length + fileList.length
					} 个文件`
				);
			},
			onSuccess(esponse, file, fileList) {
				this.fileList = fileList;
			},
			handleRemove(file, fileList) {
				this.fileList = fileList;
			},
			releaseMeeting() {
				this.dialogFormVisible = true;
			},
			queryMeetingList() {
				let _this = this
				let params = {
					limit: 4
				}
				$http
					.post("randomQueryMeetingList", params)
					.then(function(res) {
						_this.advertisement = res.data;
					})
					.catch(function() {});
			},
			queryMeetingOtherData() {
				let _this = this
				let paramsMap = {
					id: this.meeting.id
				}
				$http
					.post("queryMeetingOtherData", paramsMap, "navContent")
					.then(function(res) {
						res.data.forEach(function(item, index) {
							switch (item.classification) {
								case "banner":
									_this.banner.push(item)
									_this.bannerImg = item.fieldA
									break;
								case "manuscriptsT":
									_this.manuscriptsT.push(item)
									break;
								case "manuscriptsL":
									_this.manuscriptsL.push(item)
									break;
								case "speakers":
									_this.speakers.push(item)
									break;
								case "schedule":
									_this.schedule.push(item)
									break;
								case "register":
									_this.register.push(item)
									break;
								case "cost":
									_this.cost.push(item)
									break;
								case "payment":
									_this.payment.push(item)
									break;
								case "contact":
									_this.contact.push(item)
									break;
								case "importantData":
									_this.importantData.push(item)
									break;
								case "advertisement":
									_this.advertisement.push(item)
									break;
								default:
									break;

							}
						})

					})
					.catch(function() {});
			},
			onSubmit(releaseForm) {
				this.releaseForm.meetingDate = this.releaseForm.meetingDate.toString();
				this.releaseForm.fileList = JSON.stringify(this.fileList)
				this.$refs[releaseForm].validate((valid) => {
					if (valid) {
						let _this = this;
						$http
							.post("meetingRelease", this.releaseForm)
							.then(function(res) {
								console.log(res)
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.dialogFormVisible = false;
											this.$refs[releaseForm].resetFields();
											_this.$refs[releaseForm].resetFields();
											_this.$refs. ['upload'].clearFiles();
										},
									});
								}

							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
			meetingWeb() {
				window.open(this.meeting.meetingWeb);
			},
			objectSpanMethod({
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 0) {
					if (rowIndex % 3 === 0) {
						return {
							rowspan: 3,
							colspan: 1,
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0,
						};
					}
				}
			},
			queryMeetingCurriculum() {
				let _this = this
				let params = {}
				$http
					.post("queryMeetingCurriculum", params)
					.then(function(res) {
						_this.curriculumList = res.data;
					})
					.catch(function() {});
			},
			curriculumDetails(itemIndex) {
				this.$router.push({
					name: "curriculumDetails",
					params: this.curriculumList[itemIndex]
				});
			},
		},
		created() {
			this.meeting = this.$route.params
			this.queryMeetingOtherData()
			this.queryMeetingCurriculum();
			this.queryMeetingList();
		},
	};
</script>

<style lang="scss" scoped>
	.journalDetails2-1 {
		text-indent: 2em;
		background-color: #eeeeee;
		border: dashed 2px #aaaaaa;
		height: 80px;
		margin: 0 auto;
		width: 80%;
		color: #333333;
		margin-bottom: 20px;
	}

	.journalDetails2-2 {
		// margin-top: 20px;
		margin: 0 auto;
		width: 80%;
	}

	.meetingDetailsBg6 {
		line-height: 8px;
		font-size: 14px;
		color: #333333;
	}

	.meetingDetailsBg5 {
		color: #797979;
		width: 70%;
		line-height: 28px;
		text-align: left;
	}

	.meetingDetailsBg4 {
		font-size: 15px;
		color: #333333;
		text-align: justify;
		text-indent: 2em;
	}

	.meetingDetailsBg2 {
		border: 1px solid #ebebeb;
		padding: 0 15px 15px 15px;
	}

	.meetingDetailsBg3 {
		font-size: 20px;
		margin-right: 15px;
		font-weight: bold;
	}

	.meetingDetailsBg1 {
		margin-top: 30px;
		background-image: linear-gradient(90deg, #ccd5e0 0%, #ebf1f2 100%);
		height: 200px;
		width: 100%;
		display: flex;
	}

	.num {
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
			linear-gradient(#d40016, #d40016);
		background-blend-mode: normal, normal;
	}

	.two {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}

	.one {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}
</style>
